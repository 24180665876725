import type { SkinViews } from '!/skin'
import { defer, type Defer } from '@/helpers/promises'
import { merr } from '@setplex/merr'
import { fold } from './paths-folder'

export class SkinBatchLoader<T = SkinViews> {
  private timeoutId: ReturnType<typeof setTimeout> | null = null
  private loaded = new Map<string, Promise<T>>()
  private queue = new Map<string, Defer<T>>()

  constructor(
    private readonly base: string,
    private readonly timeout: number
  ) {}

  async fetch<T>(params: Record<string, string>): Promise<T> {
    const response = await merr(this.base, { searchParams: params })
    if (response.ok) return response.json()
    throw new Error(response.statusText)
  }

  get(path: string): Promise<T> {
    // remove trailing slash
    if (path.endsWith('/')) {
      path = path.slice(0, -1)
    }

    const view = this.loaded.get(path)
    if (view) {
      return view
    }

    // setup timeout if not set
    // this works like throttling
    if (this.timeoutId == null) {
      this.timeoutId = setTimeout(() => {
        this.timeoutId = null
        this.flush()
      }, this.timeout)
    }

    // enqueue view request
    const deferred = defer<T>()
    this.queue.set(path, deferred)
    this.loaded.set(path, deferred.promise)
    return deferred.promise
  }

  async flush() {
    // preserve current queue and create new queue instead
    // this should be faster than cloning and clearing the queue
    let dequeue: typeof this.queue | null = this.queue
    this.queue = new Map()

    // fetch all enqueued views in one request
    try {
      const views = await this.fetch<{ [path: string]: T }>({
        paths: fold(dequeue.keys()),
      })

      // resolve each dequeued view's deferred promise
      // or put loaded view into loaded map
      for (const [path, view] of Object.entries(views)) {
        const error = view == null || typeof view !== 'object'
        const deferred = dequeue.get(path)
        if (deferred) {
          if (error) {
            deferred.reject(new Error(`Skin "${path}": ${view}`))
          } else {
            deferred.resolve(view)
          }
          dequeue.delete(path) // remove resolved deferred promise from dequeue
        } else {
          // didn't request this view, put it into loaded map instead
          if (!error && !this.loaded.has(path)) {
            this.loaded.set(path, Promise.resolve(view))
          }
        }
      }

      // reject all remaining deferred promises
      for (const [path, deferred] of dequeue) {
        deferred.reject(new Error(`Skin "${path}" not found in response`))
      }
    } catch (ignore) {
      // try to load each skin individually
      for (const [path, deferred] of dequeue.entries()) {
        this.fetch<T>({
          path,
        }).then(deferred.resolve, deferred.reject)
      }
    } finally {
      dequeue = null // gc collect
    }
  }
}
