import { type Value } from '@setplex/firebase-remote-config'
import {
  TRIA_CLIENT_LANGUAGES,
  TRIA_CLIENT_PACKAGE_LOGO_HEIGHT,
} from './index.h'

export type Remote = typeof defaults
export type RemoteKeys = keyof Remote

// filter function for remote config keys
export const byKey = (key: string, _?: unknown) => key.startsWith('tria_')

export const defaults = {
  tria_isAnalyticsEnabled: false,
  tria_analyticsListenerDomain: 'https://listener.norago.tv',
  tria_isRecommendedRowsEnabled: true,
  tria_isBannersTitleAndDescriptionEnabled: true,
  tria_isResetPasswordEnabled: true,
  tria_isLiveEventsEnabled: true,
  tria_isRegistrationEnabled: true,
  tria_registrationExternalDomain: '',
  tria_defaultLanguage: 'en',
  tria_isFooterEnabled: true,
  tria_isNoraLocalizationEnabled: false,
  tria_isPackageLogoEnabled: false,
  tria_packageLogoHeight: JSON.stringify(TRIA_CLIENT_PACKAGE_LOGO_HEIGHT),
  tria_isChromecastEnabled: false,
  // tria_isRecommendedCoverEnabled: false, // obsolete, need to be removed
  tria_shoppingCartUrl: '',
  tria_isPushNotificationsEnabled: false,
  tria_languages: JSON.stringify(TRIA_CLIENT_LANGUAGES),
  tria_portalUrl: '',
  tria_subscriberAccountSettingsUrl: '',
  // Temporary solution for FP-2477
  tria_isSentryEnabled: false,
  tria_sentryIgnoreErrors: 'videojs;validatedomnesting;chrome-extension', // semicolon separated list to be converted to array of regexps//
  tria_isGooglePalEnabled: false,
  tria_isServerSentEventsEnabled: false,
  tria_playerNpawPluginConfig: '',
  tria_EXPERIMENTAL_remoteConfigRealtimeEnabled: false,
}

export const remote = Object.keys(defaults).reduce((acc, key) => {
  acc[key] = key
  return acc
}, Object.create(null)) as { [K in RemoteKeys]: K }

// prettier-ignore
export function asType(key: string, value: Value, deftype = 'string') {
  const type = typeof defaults[key as RemoteKeys]

  // this big ternary is quite readable and understandable in my opinion
  /* eslint-disable no-nested-ternary */
  /* @formatter:off */
  return type === 'boolean' ? value.asBoolean()
       : type === 'number'  ? value.asNumber()
       : type === 'string'  ? value.asString()

       // if there is no such value in defaults -> use default type
       : deftype === 'boolean' ? value.asBoolean()
       : deftype === 'number'  ? value.asNumber()
       : deftype === 'string'  ? value.asString()
                               : value.asString()

  /* eslint-enable no-nested-ternary */
}
